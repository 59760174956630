import { ContentContainer, LoadingSpinner } from '@components/Generic'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '@components/App'
import { forceRedirect } from '@/methods/forceRedirect'

const HeaderInfo = styled.h1`
  color: #00175a;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
`

const Info = styled.p`
  color: #53565a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px);
`
export const WaitingAmexPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  const handleComplete = (): void => {
    const apiUrl = process.env.WEB_API_URL
    const status = localStorage.getItem('amexMicropaymentStatus')
    const error = localStorage.getItem('amexMicropaymentError')

    if (error) {
      forceRedirect(
        `${apiUrl}/micropayment/complete?status=${status}&error=${error}`
      )
    } else {
      forceRedirect(`${apiUrl}/micropayment/complete?status=${status}`)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleComplete()
    }, 10000)
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="500px"
      padding="40px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      <HeaderInfo>
        Amex is making a credit worthiness decision on which card you are
        eligible.
      </HeaderInfo>

      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />

      <Info>Please wait as we process your application!</Info>
    </ContentContainer>
  )
})
